import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Man</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Man </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Cancer Man</p> <br/>
<p className="font-bold text-orange-500 inline-block mr-2">Traits:</p> Traditionalist, kind and nurturing, loyal and dependable, supportive and sentimental, moody, secretive, family and home oriented<br/><br/>
Compatibility Signs for Cancer men: Taurus, Virgo, Scorpio, Capricorn, and Pisces <br/><br/>
Cancer Men are soft, encouraging, loving, and caring for those they deeply care for. You may be uniquely in contact with your emotions. You can handle roles that your counterparts will be utterly lost in, like love, romance, and parenting. You don’t forgive and give a second chance to those who have hurt you intensely.<br/><br/>
You find comfort in others and care about others. 
 <br/><br/>
You can make others feel comfortable and safe. You are a protector of your family.<br/><br/>
 You are quick to love but not quick to forgive. You don’t take no for an answer. <br/><br/>
 You don’t let regret or anger get in the way of your commitment to your family or friends. You are emotionally intelligent and quick to see the good in people. You are empathetic, understanding, and considerate. 
 <br/><br/>
You possess profound and long memories and never forget the slightest and most minor events you have experienced. You will be undyingly dedicated to the ones who are kind to you. You take faith, devotion, and commitment very seriously. Hence, you wish for reassurance and open communication from your partners. <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Are you struggling to have a better understanding of your emotions and qualities?</p><br/>
Our best astrologers are here to help you by getting yourself a Free Personalized Janampatri!
 
 <br/><br/>
 
 
Let’s take a look at Cancer men traits<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Traditionalist</p><br/>
You are gracious and the incarnation of old-school principals and manners. A man full of patriotic, traditional, imbibe feelings with all family values. 
 <br/><br/>
You are a very introverted person who is not taking too much; you're shy by your nature and have a natural distrust of others. 
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Kind and nurturing</p><br/>
You are tenderhearted and possess a profound love for friends and family. You want to secure and nurture your loved and close ones. You're the kind of person who values your family and close ones above everything. Hence, you are a great family man. On the flip side, you are tacky, controlling, and self-absorbed at some point in time. 
 <br/><br/>
You have an urge to be nurtured as well as to encourage others. You usually have a powerful bond with your mother and are often called Mama's boy by your near ones.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Loyal and dependable</p><br/>
You are a highly loyal, trustworthy, and faithful blue friend and a mate. <br/><br/>
You are outstanding in taking care of your relationships. You will lend your helping hand to your close ones and are always ready to support them no matter the situation. But you can also have mood swings, deep emotions, and empathy or create distance at times.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Supportive and sentimental</p><br/>
You open-mindedly listen and understand the problems of your loved ones and try to help them possibly. That's why people have faith in you because they know that you will help them in their difficult phase; that's why they rely on you. Keep motivating your loved ones and boost their confidence. You also give them the required advice and suggestions. 
 <br/><br/>
You never forget the special occasions and events of their life. You store all fine things and incidences in your memory and stand with them when they need you.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Moody</p><br/>
Your frequently shifted moods make you in trouble, among others. Your actions and picking will also keep on changing as per your mood. People around you are confused about behavior according to your mood because you will be in your comfort zone at one moment, and on the other, you will be hanging out with friends. You will not show your soft spot to everyone. You prefer to cry and get emotional in your shell.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Secretive</p><br/>
It's tough for you to show your genuine feelings to everyone. You don't prefer to spit out what is wrong with you quickly. It is because you never want others to presume things about you. You can't readily admit to your partner when you are done in a relationship with them and don't want to continue that. Because of your shy nature, sometimes you find difficulty speaking your heart out. On the positive side, you can keep the mysteries of life alive, and people will not know what you are up to.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Home and Family Oriented</p><br/>
You' are a homely kind of person who loves to stay at home and invest your energy, ideas, and thoughts at home. 
 <br/><br/>
You will be more inclined toward the activities that can be done sitting at home, like inviting friends over for dinner, playing games, and watching TV. 
 <br/><br/>
Nothing is more vital for you in life than your relatives and family. You want to be in a relationship with someone who can take proper care and nurture your family. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">A searching for a life partner?</p><br/>
Ask Bodhi's expertise about the period when the stars will favor you in the treasure hunt for your life partner!<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">How is Cancer man in love and relationships?</p><br/>
A cancer man in love and relationship will be a long-term partner for the right woman. Your sentiments and emotions come from the heart and not the mind. You are incredibly caring, thoughtful, and spontaneous about the feelings of others, especially for loved ones and close ones. 
 <br/><br/>
You thrive on stability, security, and comfort in love and relationships. You make faithful, loyal, and supportive husbands, and with kids, you are kind and patient. Your love and relationships are well-tended and incredibly romantic, and you possess a great sense of humor.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">How To Attract Cancer Men</p><br/>
If you have a Cancerian in your sights, you may be eager to attract them to you. <br/><br/>
Here are some things you can do to win their affection:<br/><br/>
Be nice to his family and friends: Family is vital for Cancer, and they often consider their friends as part of their family. So reaching out to know family and friends of Cancer Individuals can be one of the most suitable ways to be welcomed into his life.
 <br/><br/>
Empathize with him: Cancer requires a lot of awareness and knowledge. So if they open up with you, put aside your point of view and listen to them. Be empathetic and combine with them on an emotional level.
Be vulnerable: The key to attracting a Cancer is being vulnerable and open to finding love. Cancer people are immediately drawn to people in need of help and support.
 
<br/><br/>

            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
